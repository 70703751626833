import React, { Component } from 'react';
import { Layout } from 'layouts';
import {
  PageHeader,
  TechnologyPartners,
  BrandsWorkedWithUs,
} from '@components';
import {
  BetterThanOther,
  MultiColumnList,
  MultiColumnContent,
} from '@components/industries';
import Heading from '@components/pageHeading';
import PageNavigation from '@components/pageNavigation';
import pageContent from '@constants/industries';

class SaaS extends Component {
  render() {
    const saas = pageContent.subPage.saas;
    return (
      <Layout>
        <PageHeader image={saas.headerImage} />

        <Heading
          title={saas.title}
          overview={saas.overview}
          noHeadingIcon={true}
        />

        <BetterThanOther content={saas.betterThanOther} />

        <MultiColumnContent
          content={saas.coreServices}
          highlightColumnTitle={true}
        />

        <MultiColumnList content={saas.toolset} titleHighlighted={false} />

        <MultiColumnList content={saas.capabilities} listItemTextBold={true} />

        <PageNavigation
          prev={pageContent.navigation.prev}
          next={pageContent.navigation.next}
        />

        <TechnologyPartners />

        <BrandsWorkedWithUs />
      </Layout>
    );
  }
}

export default SaaS;
